import styled from 'styled-components'

const IPCreds = ({ theme }) => {
   return (
      <Wrapper theme={theme}>
         <div>ИП Пашинин Николай Николаевич</div>
         <div>ИНН 222175069380</div>
         <div>ОГРНИП 322220200010443</div>
      </Wrapper>
   )
}

export default IPCreds

const Wrapper = styled.div`
   color: #455f76;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 3px;
   padding-bottom: 20px;
`
