import styled from 'styled-components'
import BaseButton from '../../baseButton/BaseButton'
import LinkButton from '../../linkButton/LinkButton'

const AboutItem = ({ id, name, link, image }) => {
   return (
      <Wrapper>
         <div>
            <TextWrap>
               <span>0{id}</span>
               <p>{name}</p>
            </TextWrap>

            <ActiveWrapper>
               <BaseButton text={'Подробнее'} link={link}></BaseButton>
               <LinkButton link={link} />
            </ActiveWrapper>
         </div>
         <img src={image} alt='' />
      </Wrapper>
   )
}

export default AboutItem

const TextWrap = styled.div`
   height: 97px;
`

const Wrapper = styled.div`
   background: #23323f;
   border-radius: 16px;
   letter-spacing: -0.02em;
   display: flex;
   justify-content: space-between;
   margin-top: 14px;
   padding: 20px 15px;
   align-items: center;
   > div {
      min-width: 210px;
   }

   > img {
      height: 120px;
   }

   span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #beff8b;
   }
   p {
      font-size: 23px;
      font-weight: 700;
      line-height: 31.2px;
      color: #fff;
      width: 90%;
      margin-top: 0;
      margin-bottom: 22px;
   }
`
const ActiveWrapper = styled.div`
   display: flex;
`
