import {
   SET_PRICE_RANGE,
   SET_FILTER_OPTION,
   FETCH_FILTERS,
   FETCH_FILTERS_SUCCESS,
} from '../actions/filterActions'

const initialState = {
   loading: false,
   error: null,
   priceRange: { from: null, to: null },
   propertyTypes: [],
   countries: [],
   residentialRealEstateTypes: [],
   commercialRealEstateTypes: [],
   paymentMethods: [],
   investionMethods: [],
   biz: [],
   selectedPropertyTypes: [],
   selectedOptions: {},
}

const filterReducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_FILTERS:
         return {
            ...state,
         }
      case FETCH_FILTERS_SUCCESS:
         const {
            propertyTypes,
            countries,
            residentialRealEstateTypes,
            commercialRealEstateTypes,
            paymentMethods,
            investionMethods,
            biz,
         } = action.payload
         return {
            ...state,
            propertyTypes: propertyTypes,
            countries: countries,
            residentialRealEstateTypes: residentialRealEstateTypes,
            commercialRealEstateTypes: commercialRealEstateTypes,
            paymentMethods: paymentMethods,
            investionMethods: investionMethods,
            biz: biz,
         }
      case SET_FILTER_OPTION:
         const { filterId, options } = action.payload
         let selectedPropertyTypes = state.selectedPropertyTypes
         if (filterId === 1) {
            selectedPropertyTypes = options.map(option => option.fv)
         }
         return {
            ...state,
            selectedOptions: {
               ...state.selectedOptions,
               [filterId]: options,
            },
            selectedPropertyTypes: selectedPropertyTypes
         }
      case SET_PRICE_RANGE:
         return {
            ...state,
            priceRange: action.payload,
         }
      default:
         return state
   }
}

export default filterReducer
