import InterItem from './InterItem/InterItem'
import Ball from '../../image/ball.png'
import Case from '../../image/case.png'
import Graph from '../../image/graphick.png'
import AirCar from '../../image/airCar.png'
import Football from '../../image/football.png'
import Book from '../../image/book.png'
import styled from 'styled-components'

const offerArr = [
   {
      id: 1,
      name: 'Обучение',
      link: '#',
      image: Ball,
   },
   {
      id: 2,
      name: 'Бизнес-события',
      link: '#',
      image: Case,
   },
   {
      id: 3,
      name: 'Инвестиции',
      link: '#',
      image: Graph,
   },
   {
      id: 4,
      name: 'Путешествия',
      link: '#',
      image: AirCar,
   },
   {
      id: 5,
      name: 'Спорт',
      link: '#',
      image: Football,
   },
   {
      id: 6,
      name: 'Чтение',
      link: '#',
      image: Book,
   },
]

const Interesting = ({ theme }) => {
   return (
      <Wrapper className={'wrapper'}>
         <Title>
            <Name theme={theme}>Интересы</Name>
         </Title>

         <ItemsWrapper>
            <SliderWrapper>
               {offerArr.map((item) => (
                  <InterItem key={item.id} {...item} />
               ))}
            </SliderWrapper>
         </ItemsWrapper>
      </Wrapper>
   )
}

export default Interesting

const SliderWrapper = styled.div`
   display: flex;
   flex-wrap: nowrap;
   gap: 10px;
   justify-content: space-between;
`

const ItemsWrapper = styled.div`
   overflow-x: scroll;
   margin-top: 30px;
   scrollbar-width: none;
`

const Name = styled.div`
   font-size: 30px;
   font-weight: 700;
   line-height: 39px;
   letter-spacing: -0.02em;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
`

const Wrapper = styled.div`
   overflow: hidden;
`

const Title = styled.div`
   display: flex;
   justify-content: space-between;
   margin-top: 35px;
`
