import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import $api from '../../http'
import EventPrice from './EventPrice'
import Gallery from '../gallery/Gallery'

const EventCard = ({ user, theme }) => {
   const params = useParams()
   const [item, setItem] = useState({})
   useMemo(async () => {
      const response = await $api.get(`/event/${params.id}`)
      setItem(response.data)
      console.log(response.data)
   }, [params.id])

   return (
      <Wrapper className='wrapper'>
         <Img src={`/media/${item.image}`} alt='banner' />
         <Label>
            {item.category?.name === 'Бизнес' ? (
               <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M14.25 8C13.836 8 13.5 7.70133 13.5 7.33333V6.33333H10.5V7.33333C10.5 7.70133 10.164 8 9.75 8C9.336 8 9 7.70133 9 7.33333V6.33333C9 5.598 9.67275 5 10.5 5H13.5C14.3273 5 15 5.598 15 6.33333V7.33333C15 7.70133 14.664 8 14.25 8Z' />
                  <path d='M16.5 12.7353V13.4388C16.5 13.7524 16.245 14.0124 15.9375 14.0124C15.63 14.0124 15.375 13.7524 15.375 13.4388V12.7353H8.625V13.4388C8.625 13.7524 8.37 14.0124 8.0625 14.0124C7.755 14.0124 7.5 13.7524 7.5 13.4388V12.7353H3V17.8971C3 19.0594 3.9225 20 5.0625 20H18.9375C20.0775 20 21 19.0594 21 17.8971V12.7353H16.5ZM18.9375 7H5.0625C3.9225 7 3 7.94059 3 9.10294V11.5882H7.5V10.6324C7.5 10.3188 7.755 10.0588 8.0625 10.0588C8.37 10.0588 8.625 10.3188 8.625 10.6324V11.5882H15.375V10.6324C15.375 10.3188 15.63 10.0588 15.9375 10.0588C16.245 10.0588 16.5 10.3188 16.5 10.6324V11.5882H21V9.10294C21 7.94059 20.0775 7 18.9375 7Z' />
               </svg>
            ) : item.category?.name === 'Психология' ? (
               <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M9 1.6875C9 1.23995 9.17775 0.81072 9.49421 0.494258C9.81067 0.177795 10.24 0 10.6875 0C11.135 0 11.5643 0.177795 11.8808 0.494258C12.1973 0.81072 12.375 1.23995 12.375 1.6875V2.25C12.375 2.54837 12.4936 2.83452 12.7045 3.0455C12.9155 3.25647 13.2016 3.375 13.5 3.375H16.875C17.1733 3.375 17.4595 3.49353 17.6705 3.7045C17.8814 3.91548 18 4.20163 18 4.5V7.875C18 8.17337 17.8814 8.45952 17.6705 8.6705C17.4595 8.88147 17.1733 9 16.875 9H16.3125C15.865 9 15.4357 9.17775 15.1192 9.49421C14.8027 9.81067 14.625 10.24 14.625 10.6875C14.625 11.135 14.8027 11.5643 15.1192 11.8808C15.4357 12.1973 15.865 12.375 16.3125 12.375H16.875C17.1733 12.375 17.4595 12.4936 17.6705 12.7045C17.8814 12.9155 18 13.2016 18 13.5V16.875C18 17.1733 17.8814 17.4595 17.6705 17.6705C17.4595 17.8814 17.1733 18 16.875 18H13.5C13.2016 18 12.9155 17.8814 12.7045 17.6705C12.4936 17.4595 12.375 17.1733 12.375 16.875V16.3125C12.375 15.865 12.1973 15.4357 11.8808 15.1192C11.5643 14.8027 11.135 14.625 10.6875 14.625C10.24 14.625 9.81067 14.8027 9.49421 15.1192C9.17775 15.4357 9 15.865 9 16.3125V16.875C9 17.1733 8.88147 17.4595 8.6705 17.6705C8.45952 17.8814 8.17337 18 7.875 18H4.5C4.20163 18 3.91548 17.8814 3.7045 17.6705C3.49353 17.4595 3.375 17.1733 3.375 16.875V13.5C3.375 13.2016 3.25647 12.9155 3.0455 12.7045C2.83452 12.4936 2.54837 12.375 2.25 12.375H1.6875C1.23995 12.375 0.81072 12.1973 0.494258 11.8808C0.177795 11.5643 0 11.135 0 10.6875C0 10.24 0.177795 9.81067 0.494258 9.49421C0.81072 9.17775 1.23995 9 1.6875 9H2.25C2.54837 9 2.83452 8.88147 3.0455 8.6705C3.25647 8.45952 3.375 8.17337 3.375 7.875V4.5C3.375 4.20163 3.49353 3.91548 3.7045 3.7045C3.91548 3.49353 4.20163 3.375 4.5 3.375H7.875C8.17337 3.375 8.45952 3.25647 8.6705 3.0455C8.88147 2.83452 9 2.54837 9 2.25V1.6875Z' />
               </svg>
            ) : item.category?.name === 'Криптовалюта' ? (
               <svg width='21' height='21' viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M19.1543 0.53125C18.3532 0.53125 17.6768 1.04715 17.4219 1.76172H16.0804C15.4621 1.76172 14.8877 2.0687 14.5447 2.58363L12.7946 5.20879L10.7499 4.27569C10.5908 4.20503 10.4092 4.20503 10.2502 4.27569L8.20553 5.20879L6.45531 2.58363C6.11229 2.0687 5.53791 1.76172 4.91963 1.76172H3.57813C3.32323 1.04715 2.64679 0.53125 1.8457 0.53125C0.828003 0.53125 0 1.35925 0 2.37695C0 3.39465 0.828003 4.22266 1.8457 4.22266C2.64679 4.22266 3.32323 3.70676 3.57813 2.99219H4.91963C5.12567 2.99219 5.31729 3.09425 5.43153 3.26616L7.07151 5.72614L5.36552 6.50463L10.5 9.07179L15.6345 6.50463L13.9285 5.72614L15.5685 3.26616C15.6827 3.09441 15.8743 2.99219 16.0804 2.99219H17.4219C17.6768 3.70676 18.3532 4.22266 19.1543 4.22266C20.172 4.22266 21 3.39465 21 2.37695C21 1.35925 20.172 0.53125 19.1543 0.53125Z' />
                  <path d='M19.1543 16.5267C18.3532 16.5267 17.6768 17.0426 17.4219 17.7572H16.0804C15.8743 17.7572 15.6827 17.6551 15.5685 17.4832L13.9769 15.0958L15.6971 14.2358C15.9056 14.1313 16.0371 13.9185 16.0371 13.6854V7.67871L11.1152 10.1395V16.5267L12.8679 15.6505L14.5447 18.1657C14.8877 18.6807 15.4621 18.9876 16.0804 18.9876H17.4219C17.6768 19.7022 18.3532 20.2181 19.1543 20.2181C20.172 20.2181 21 19.3901 21 18.3724C21 17.3547 20.172 16.5267 19.1543 16.5267Z' />
                  <path d='M4.91963 17.7573H3.57813C3.32323 17.0428 2.64679 16.5269 1.8457 16.5269C0.828003 16.5269 0 17.3549 0 18.3726C0 19.3903 0.828003 20.2183 1.8457 20.2183C2.64679 20.2183 3.32323 19.7024 3.57813 18.9878H4.91963C5.53791 18.9878 6.11229 18.6808 6.45531 18.1659L8.13215 15.6506L9.88477 16.5269V10.1396L4.96289 7.67871V13.6856C4.96289 13.9187 5.09443 14.1315 5.30287 14.2359L7.02312 15.096L5.43153 17.4834C5.31745 17.6553 5.12567 17.7573 4.91963 17.7573Z' />
               </svg>
            ) : (
               <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M12 2L14.8478 7.58031L21.035 8.56434L16.6079 12.9972L17.584 19.1857L12 16.345L6.41604 19.1857L7.39213 12.9972L2.96496 8.56434L9.15218 7.58031L12 2Z' />
               </svg>
            )}
         </Label>
         <Descr>
            <Title>{item.title}</Title>
            <Info>
               <span>Дата:</span>
               {item.date}
            </Info>
            <Info>
               <span>Место проведения:</span>
               {item.place}
            </Info>
            <Description dangerouslySetInnerHTML={{ __html: item?.description }} />
            {/* <PricesWrapper>
               <div>
                  <PayText>Цена</PayText>
                  <Price>{formatNumber(item.payment_rates?.[0].price)} CUN</Price>
               </div>
               <Button>Купить</Button>
            </PricesWrapper> */}
            { item.is_booking_available && !item.is_closed && !item.form_link && (
            <PricesWrapper>
               {item.payment_rates?.map((item) => (
                  <EventPrice key={item.id} {...item} user={user} theme={theme} />
               ))}
            </PricesWrapper>
            )}
            {item.form_link && !item.is_closed && (
               <PricesWrapper>
                  <Button
                     onClick={(e) => {
                        e.stopPropagation()
                        window.open(item.form_link, '_blank');
                     }}
                  >
                     Забронировать
                  </Button>
               </PricesWrapper>
            )}
            {item.is_closed && (
               <div style={{paddingBottom: "15px"}}> 
               <Gallery images={item.images}/>
               </div>
            )
            }
         </Descr>
      </Wrapper>
   )
}
//yX5fK9pZ1wfN
export default EventCard

const PricesWrapper = styled.div`
   margin: 26px 0 19px 0;
   padding: 0 18px;
`

const Info = styled.div`
   margin-top: 14px;
   font-size: 16px;
   font-weight: 600;
   padding: 0 17px;
   color: #617283;
   line-height: 20px;
   span {
      color: #000;
      margin-right: 3px;
   }
`

const Description = styled.div`
   font-size: 15px;
   line-height: 20.3px;
   padding: 0px 18px;
   margin-top: 22px;
   p {
      margin: 0px;
   }
`

const Label = styled.div`
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #beff8b;
   position: absolute;
   top: 8px;
   right: 8px;
   height: 40px;
   width: 40px;
   svg {
      fill: #000;
   }
`

const Wrapper = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 0px;
   margin: 23px auto 19px auto;
   position: relative;
`

const Img = styled.img`
   width: 100%;
   border-radius: 16px 16px 0 0;
`
const Descr = styled.div`
   border-radius: 0 0 16px 16px;
   background: #fff;
`

const Title = styled.div`
   padding: 22px 18px 0px 18px;
   letter-spacing: 0.03rem;
   font-size: 23px;
   font-weight: 700;
`
const Button = styled.div`
   font-size: 14px;
   padding: 10px 0;
   width: 100%;
   background: #beff8b;
   border-radius: 28px;
   cursor: pointer;
   transition: all 0.3s ease;
   text-align: center;
   color: #000;
   margin-top: 25px;
   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`
// const PricesWrapper = styled.div`
//    display: flex;
//    justify-content: space-between;
//    padding: 15px 19px 19px 19px;
//    align-items: self-end;
// `

// const PayText = styled.div`
//    font-size: 12px;
//    font-weight: 400;
//    margin-top: 4px;
// `

// const Price = styled.div`
//    font-size: 20px;
//    font-weight: 700;
//    margin-top: 4px;
//    font-family: system-ui;
// `
