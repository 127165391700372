import classes from "./GridBotLink.module.css";
// import ArrowImg from "../../image/arrowLink.svg";

const GridBotLink = ({ name, imgUrl, handleClick, theme }) => {
  return (
    <div className={classes.wrapper} onClick={handleClick}>
      <div className={classes.imgWrapper}>
        <img src={imgUrl} alt="img" />
      </div>
      <div
        className={classes.title}
        style={{ color: theme === "dark" ? "#fff" : "#000" }}
      >
        {name}
      </div>
      {/* <img src={ArrowImg} alt="arrow" className={classes.arrowImg} /> */}
      <div
        style={{ fill: theme === "dark" ? "#fff" : "#000" }}
        className={classes.arrowImg}
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 14L14 1M14 1H5.48276M14 1V9.06897"
            stroke={theme === "dark" ? "white" : "black"}
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  );
};

export default GridBotLink;
