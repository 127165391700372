import styled from 'styled-components'
import { useTelegram } from '../../hooks/useTelegram'
import { useNavigate } from 'react-router-dom'

const BaseButton = ({ text, link, mainButton, href }) => {
   const { webApp } = useTelegram()
   const navigate = useNavigate()
   return (
      <>
         {href ? (
            <Link href={href} target='_blank'>
               {text}
            </Link>
         ) : (
            <Wrapper
               onClick={() => {
                  webApp.BackButton.show()
                  navigate(link)
                  mainButton && webApp.MainButton.show()
               }}
            >
               {text}
            </Wrapper>
         )}
      </>
   )
}

export default BaseButton

const Link = styled.a`
   font-size: 14px;
   font-weight: 400;
   line-height: 18.2px;
   padding: 10px 26px;
   border-radius: 30px;
   border: none;
   cursor: pointer;
   transition: all 0.3s ease;
   background: #fff;
   color: #000;
   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`

const Wrapper = styled.div`
   font-size: 14px;
   font-weight: 400;
   line-height: 18.2px;
   padding: 10px 22px;
   border-radius: 30px;
   border: none;
   cursor: pointer;
   transition: all 0.3s ease;
   background: #fff;
   color: #000;
   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`
