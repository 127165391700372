import classes from './ChampionBlock.module.css'
import Champion from '../../image/champion.png'
import LinkButton from '../linkButton/LinkButton'
import styled from 'styled-components'

const ChampionBlock = ({ theme }) => {
   return (
      <Wrapper className={`${classes.wrapper}  wrapper `} theme={theme}>
         <img src={Champion} alt='champion' />
         <p>
            <span>
               Сообщество ДЕЛО - это большая система, способная дать мощный буст для каждого члена
               сообщества.
            </span>
            <br />
            Потому что для успешного и постоянного роста необходимо поддерживать и развивать
            полезные связи, посещать крупные мероприятия, качественно отдыхать, много обучаться и
            работать над физическим здоровьем!
         </p>
         <LinkWrapper>
            <LinkButton color={'gray'} link={'#social'} />
         </LinkWrapper>
      </Wrapper>
   )
}

export default ChampionBlock

const Wrapper = styled.div`
   margin-top: 90px;
   display: flex;
   align-items: center;
   flex-direction: column;
   letter-spacing: -0.02em;
   font-size: 15px;
   line-height: 20.8px;
   padding-bottom: 21px;
   border-radius: 16px;
   position: relative;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
   img {
      margin: 0 auto;
   }

   p {
      font-weight: 400;
      font-size: 16px;
      width: 90%;
      margin-top: -4px;
      > span {
         font-weight: 700;
         margin-bottom: 0px;
         display: block;
         width: 100%;
      }
   }
`

const LinkWrapper = styled.div`
   position: absolute;
   bottom: -21px;
`
