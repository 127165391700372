import classes from './ModalsGrid.module.css'
import PaymentCard from '../../image/PaymentCard.svg'
import styled from 'styled-components'
import { useState } from 'react'
import $api from '../../http'
import { useSelector } from 'react-redux'
import BtnSpinner from '../btnSpinner/BtnSpinner';

const ModalDepProfile = ({ onCloseModal, theme }) => {
   const [amountBalance, setAmountBalance] = useState(null);
   const [currencyType, setCurrencyType] = useState('RUB');
   const [natification, setNatification] = useState("");
   const currency = useSelector((state) => state?.app?.currencies);
   const [loading, setLoading] = useState(false);
   const minLimit = 5; 

   const paymentFn = async () => {
      if(currencyType === 'USDT'){
         if(minLimit > amountBalance){
            setNatification(`Минимальная сумма пополнения ${minLimit}$!`);
            setTimeout(() => {
               setNatification("");
               setAmountBalance(null);
             }, 3000);
            return;
         }
      }


      setLoading(true);
      const endpoint = currencyType === 'RUB' ? '/top_up_balance' : '/top_up_plisio';
      const responce = await $api.post(endpoint, { amount: currencyType === 'RUB' ? amountBalance :  amountBalance})

      if (responce?.statusText === 'OK' || responce?.status === 200) {
         const paymentUrl = responce?.data?.payment_url
         console.log(paymentUrl);
         if (paymentUrl) {
            if(endpoint === '/top_up_balance'){
               //window.open(paymentUrl, '_blank')
               window.Telegram.WebApp.openLink(paymentUrl);
            }
            else{
               window.Telegram.WebApp.openLink(paymentUrl);
               //window.location.href = paymentUrl;
            }  
         }
         setLoading(false);
      }
      onCloseModal()
   }
   const handleCurrencyChange = (currency) => {
      setCurrencyType(currency)
      console.log(currencyType)
   }

   return (
      <div className={classes.wrapper}>
         <img src={PaymentCard} alt='PaymentCard' />
         <Title theme={theme} className={classes.title}>
            Пополнить баланс
            <br />
            Пополнение баланса занимает до 5 минут!
         </Title>
         <ExchangeRate theme={theme} className={classes.exchangeRate}>
            <p>1 CUN = 1 RUB</p>
            <p>1 CUN = {(1 / currency).toFixed(5)} USDT</p>
            {amountBalance && currencyType && (
               <p>{amountBalance} {currencyType} = {currencyType === 'RUB' ? amountBalance : amountBalance*currency} CUN</p>
            )}
         </ExchangeRate>
         <CurrencySelector theme={theme}>
            <CurrencyOption 
               active={currencyType === 'RUB'} 
               onClick={() => handleCurrencyChange('RUB')}
               theme={theme}
            >
               RUB
            </CurrencyOption>
            <CurrencyOption 
               active={currencyType === 'USDT'} 
               onClick={() => handleCurrencyChange('USDT')}
               theme={theme}
            >
               USDT
            </CurrencyOption>
         </CurrencySelector>
         <Input
            type='number'
            value={amountBalance}
            inputMode="decimal"
            pattern="[0-9]*"
            onChange={(e) => setAmountBalance(e.target.value)}
            placeholder={ currencyType === 'RUB' ? '100 RUB' : '5 USDT'}
         />
         {loading ? (
            <Submit loading={loading}>
               <BtnSpinner />
            </Submit>
            ) : (
            <Submit onClick={paymentFn} loading={loading}>
               Отправить запрос
            </Submit>
         )}
         {natification && (
            <Natification theme={theme} className={classes.natification}>
               {natification}
            </Natification>
         )}
      </div>
   )
}

export default ModalDepProfile

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;
const ExchangeRate = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const CurrencySelector = styled.div`
  display: flex;
  width: 90%;
  margin: 20px auto;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

const CurrencyOption = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  background: ${(props) => (props.active ? '#beff8b' : '#f1f1f1')};
  color: ${(props) => (props.theme === "dark" ? "#000" : "#000")};
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: ${(props) => (props.active ? '#a0df70' : '#e1e1e1')};
  }
`;

const Input = styled.input`
  padding: 10px;
  width: 90%;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-top: 20px;
  text-align: center;
  font-family: system-ui;
`;

const Submit = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 90%;
  padding: 9px;
  background: #beff8b;
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px gray;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 20px gray;
  }
`;
const Natification = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  position: fixed;
  bottom: 5px;
  right: 20px;
  ${(props) =>
    props.theme === "light"
      ? "background: #000; color: #fff;"
      : "background: #fff; color: #000;"}
`;