import { useState, useEffect } from 'react'
import styled from 'styled-components'
import $api from '../../http'
import classes from './PersonalInfo.module.css'

const PersonalInfo = ({ theme, user }) => {
   const initialFirstName = user?.first_name ?? ''
   const initialLastName = user?.last_name ?? ''
   const initialNick = user?.telegram_username ?? ''
   const initialEmail = user?.working_email ?? ''
   const initialAgreement = user?.agreement

   const [checkAgree, setChechAgree] = useState(false)

   useEffect(() => {
      if (user?.working_email) {
         setChechAgree(true)
      }
   }, [user])

   const [firstName, setFirstName] = useState(initialFirstName)
   const [lastName, setLastName] = useState(initialLastName)
   const [nick, setNick] = useState(initialNick)
   const [email, setEmail] = useState(initialEmail)
   const [agreement, setAgreement] = useState(initialAgreement)

   useEffect(() => {
      setFirstName(initialFirstName)
      setLastName(initialLastName)
      setNick(initialNick)
      setEmail(initialEmail)
      setAgreement(initialAgreement)
   }, [initialFirstName, initialLastName, initialNick, initialEmail, initialAgreement])

   const handleFirstNameChange = (e) => setFirstName(e.target.value)
   const handleLastNameChange = (e) => setLastName(e.target.value)
   const handleNickChange = (e) => setNick(e.target.value)
   const handleEmailChange = (e) => setEmail(e.target.value)
   const [natification, setNatification] = useState('')

   const handleSaveBtn = async (firstName, lastName, nick, email, agreement) => {
      const userInfo = {
         first_name: firstName,
         last_name: lastName,
         telegram_username: nick,
         working_email: email,
         agreement: agreement,
      }
      if (!firstName || !lastName || !nick || !email || !agreement) {
         setNatification('Введите все поля!')
      } else {
         await $api.post(`/user`, userInfo)
         setNatification('Ваши данные успешно изменены!')
      }
      setTimeout(() => {
         setNatification('')
      }, 3000)
   }

   return (
      <Wrapper className={'wrapper'} theme={theme}>
         <Title theme={theme}>Персональная информация</Title>
         <input type='text' value={firstName} placeholder='Имя' onChange={handleFirstNameChange} />
         <input
            type='text'
            value={lastName}
            placeholder='Фамилия'
            onChange={handleLastNameChange}
         />
         <input type='text' value={nick} placeholder='Ник в telegram' onChange={handleNickChange} />
         {checkAgree ? (
            <EmailDis>{initialEmail}</EmailDis>
         ) : (
            <input type='text' value={email} placeholder='Email' onChange={handleEmailChange} />
         )}
         {!checkAgree && (
            <CheckBoxWrapper theme={theme}>
               <CheckBox theme={theme} agree={agreement} onClick={() => setAgreement(!agreement)} />
               Согласие на использовании персональных данных.
            </CheckBoxWrapper>
         )}

         <button onClick={() => handleSaveBtn(firstName, lastName, nick, email, agreement)}>
            {checkAgree ? 'Сохранить' : 'Активировать аккаунт'}
         </button>
         {natification && (
            <Natification theme={theme} className={classes.natification}>
               {natification}
            </Natification>
         )}
      </Wrapper>
   )
}

export default PersonalInfo

const Natification = styled.div`
   padding: 10px 20px;
   font-size: 14px;
   border-radius: 8px;
   position: fixed;
   bottom: 20px;
   right: 20px;
   ${(props) =>
      props.theme === 'light' ? 'background: #000; color: #fff;' : 'background: #fff; color: #000;'}
`

const EmailDis = styled.div`
   padding: 15px 20px;
   font-size: 15px;
   border: none;
   border-radius: 30px;
   width: 87%;
   margin-top: 8px;
   background: var(--secondary-bg-color);
   color: #aeaeae;
   transition: all 0.3s ease;
   &:hover {
      box-shadow: 1px 2px 15px gray;
   }
`

const CheckBoxWrapper = styled.div`
   display: flex;
   gap: 10px;
   color: ${(props) => (props.theme === 'light' ? '#11141b;' : '#fff')};
   font-size: 12px;
   align-items: center;
   margin-bottom: 6px;
   margin-top: 10px;
`
const CheckBox = styled.div`
   border: 1px solid ${(props) => (props.theme === 'dark' ? '#686868' : '#000')};
   border-radius: 4px;
   width: 17px;
   height: 17px;
   background: ${(props) => (props.agree ? '#beff8b' : 'none')};
   &:hover {
      box-shadow: gray 0px 0px 6px;
      cursor: pointer;
   }
`

const Title = styled.div`
   font-size: 22px;
   font-weight: 700;
   line-height: 31.2px;
   letter-spacing: -0.02em;
   margin-bottom: 10px;
   color: ${(props) => (props.theme === 'light' ? '#11141b;' : '#fff')};
`

const Wrapper = styled.div`
   margin-top: 35px;
   background: var(--bg_color);
   border-radius: 13px;
   padding: 27px 17px 36px 17px;
   width: 84%;
   input {
      padding: 15px 20px;
      font-size: 15px;
      border: none;
      border-radius: 30px;
      width: 87%;
      margin-top: 8px;
      background: var(--secondary-bg-color);
      color: ${(props) => (props.theme === 'light' ? '#11141b;' : '#fff')};
      transition: all 0.3s ease;
      &:placeholder {
         color: var(--gray-text-color);
      }
      &:hover {
         box-shadow: 1px 2px 15px gray;
      }
   }
   button {
      width: 99%;
      padding: 13px 15px;
      text-align: center;
      background: #beff8b;
      color: #11141b;
      border: none;
      border-radius: 30px;
      margin-top: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
         transform: scale(1.1);
         box-shadow: 1px 2px 20px gray;
      }
   }
`
