// actions/timeActions.js
export const SET_CURRENCY_LAST_UPDATE = 'SET_CURRENCY_LAST_UPDATE';
export const SET_USER_LAST_UPDATE = 'SET_USER_LAST_UPDATE';

export const setCurerncyLastUpdate = (time) => ({
  type: SET_CURRENCY_LAST_UPDATE,
  payload: time,
});

export const setUserLastUpdate = (time) => ({
  type: SET_USER_LAST_UPDATE,
  payload: time,
});
