import { 
    INIT_PROPERTY_PAGE,
    SAVE_END_INDEX,
    SAVE_SCROLL_TOP,
    SET_SELECTED_GOOD,
} from "../actions/propertyActions";

const initialState = {
    endIndex: 12,
    scrollTop: 0,
    selectedGood: -1,
}
const propertyReducer = (state = initialState, action) => {
    switch(action?.type){
        case INIT_PROPERTY_PAGE:
            return{
                ...state,
            }
        case SAVE_END_INDEX:
            return {
                ...state,
                endIndex: action.payload
            };
        case SAVE_SCROLL_TOP:
            return {
                ...state,
                scrollTop: action.payload
            };
        case SET_SELECTED_GOOD:
            return {
                ...state,
                selectedGood: action.payload,
            }
        default:
        return state;
    }
}
export default propertyReducer;