import Delo from '../../image/DELO.svg'
import styled from 'styled-components'
import BGImage from '../../image/descriptionBG.jpg'

const Description = () => {
   return (
      <Wrapper className={'wrapper'}>
         <img src={Delo} alt='' />
         <Text>
            « Я был поражен тем, как важно предпринимать действия. Недостаточно просто знать, нужно
            использовать знания. Мало хотеть чего-то, нужно делать.» Леонардо Да Винчи
         </Text>
      </Wrapper>
   )
}

export default Description

const Wrapper = styled.div`
   margin-top: 25px;
   background-image: url(${BGImage});
   height: 194px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   border-top-left-radius: 35px;
   border-bottom-right-radius: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   z-index: 1;
   > img {
      margin-top: 4px;
      width: 184px;
   }
`

const Text = styled.div`
   font-size: 13px;
   font-style: italic;
   font-weight: 700;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   color: #fff;
   margin-top: 19px;
   width: 80%;
`
