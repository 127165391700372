import styled from 'styled-components'
import LinkButton from '../../linkButton/LinkButton'

const InterItem = ({ name, link, image }) => {
   return (
      <Wrapper name={name}>
         <img src={image} alt='' />
         <div>{name}</div>
         <LinkWrapper>
            <LinkButton color={name === 'Спорт' ? 'black' : 'white'} link={link} />
         </LinkWrapper>
      </Wrapper>
   )
}

export default InterItem

const LinkWrapper = styled.div`
   position: absolute;
   top: 12px;
   right: 12px;
`

const Wrapper = styled.div`
   border-radius: 16px;
   position: relative;
   padding: 15px 20px;
   height: 131px;
   min-width: 183px;
   color: #fff;
   font-size: 22px;
   font-weight: 700;
   line-height: 28.6px;
   letter-spacing: -0.02em;
   color: ${(props) =>
      props.name === 'Обучение' || props.name === 'Путешествия' || props.name === 'Спорт'
         ? '#000'
         : '#fff'};

   background: ${(props) =>
      props.name === 'Обучение'
         ? '#7CFF2B'
         : (props) =>
              props.name === 'Бизнес-события'
                 ? '#23323F'
                 : (props) =>
                      props.name === 'Путешествия'
                         ? '#BEFF8B'
                         : (props) => (props.name === 'Спорт' ? '#fff' : '#5F7385')};

   > img {
      height: 78px;
   }
   > div {
      margin-top: 12px;
   }
`
