import $api from '../../http'
import { finishFetchingCatalogData } from '../actions/catalogActions'
import Room1 from '../../image/room1.png'
import Room2 from '../../image/room2.png'
import Room3 from '../../image/room3.png'
import Room4 from '../../image/room4.png'

export const catalogData = (useCloudStorageResult, useTelegramResult) => async (dispatch) => {
   const response = await $api.get('/complex')
   if (response.status === 200) {
   }
   const response2 = await $api.get('/estate')
   if (response2.status === 200) {
      var r = transformData(response2.data.items)
      dispatch(finishFetchingCatalogData(r))
   }
   if (response.status !== 200) {
      const data = [
         {
            id: 1,
            name: 'Bodrum',
            price: 700000,
            city: 'Москва',
            photoUrl: Room1,
            propertyType: 'residential',
            residentialRealEstateType: 'flat',
            commercialRealEstateType: null,
            country: 'Russia',
            paymentMethod: 'cash',
            rooms: 1,
         },
         {
            id: 2,
            name: 'Alanya',
            price: 950000,
            city: 'Санкт-Петербург',
            photoUrl: Room2,
            propertyType: 'residential',
            residentialRealEstateType: 'village',
            commercialRealEstateType: null,
            country: 'Russia',
            paymentMethod: 'split',
            rooms: 2,
         },
         {
            id: 3,
            name: 'Marmaris',
            price: 620000,
            city: 'Сочи',
            photoUrl: Room3,
            propertyType: 'residential',
            residentialRealEstateType: 'nearBySea',
            commercialRealEstateType: null,
            country: 'Russia',
            paymentMethod: 'mortgage',
            rooms: 4,
         },
         {
            id: 4,
            name: 'Bodrum',
            price: 440000,
            city: 'Екатеринбург',
            photoUrl: Room4,
            propertyType: 'residential',
            residentialRealEstateType: 'elite',
            commercialRealEstateType: null,
            country: 'Russia',
            paymentMethod: 'crypto',
            rooms: 5,
         },
      ]
      dispatch(finishFetchingCatalogData(data))
   }
   //if(response.status === 200){
   // dispatch(finishFetchingCatalogData(response.data));
   // }
}

function transformData(data) {
   const defaultImages = [Room1, Room2, Room3, Room4]
   return data.map((item) => ({
      id: item.id,
      name: item.descriptions[1],
      price: item.price,
      city: item.city,
      photoUrl:
         item.images.length > 0
            ? item.images[0]
            : defaultImages[Math.floor(Math.random() * defaultImages.length)],
      propertyType: item.type === 'living' ? 'residential' : 'commercial',
      residentialRealEstateType: 'elite',
      commercialRealEstateType: null,
      country: item.country,
      paymentMethod: item.payments,
      rooms: item.num_rooms,
   }))
}
