import styled from 'styled-components'
import PeopleIcon from '../../image/PeopleIcon.svg'

const ModalReferalInfo = () => {
   const referalArr = [
      {
         count: 157,
         cash: 124421,
         payDay: 12412412,
         cashBack: 1234124,
         id: 1,
      },
      {
         count: 2412,
         cash: 124421,
         payDay: 12412412,
         cashBack: 1234124,
         id: 2,
      },
      {
         count: 412,
         cash: 124421,
         payDay: 12412412,
         cashBack: 1234124,
         id: 3,
      },
   ]

   const formatNumber = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   }

   return (
      <>
         <Title>
            Реферальная
            <br />
            структура
         </Title>
         <LinesWrapper>
            {referalArr.map((ref, index) => (
               <NumberLine key={ref.id}>
                  <TitleLine>
                     <Name>{`${index + 1}-я линия`}</Name>
                     <Count>
                        <img src={PeopleIcon} alt='PeopleIcon' />
                        {`(${ref.count})`}
                     </Count>
                  </TitleLine>
                  <PriceLine>
                     <LineCounts>
                        <Text>Общая сумма внесенных средств</Text>
                        <Price>{formatNumber(ref.cash)} $</Price>
                     </LineCounts>
                     <LineCounts>
                        <Text>Общая сумма заработка линии в месяц</Text>
                        <Price>{formatNumber(ref.payDay)} $</Price>
                     </LineCounts>
                     <LineCounts>
                        <Text>Заработок клиента в месяц с первой линии</Text>
                        <Price>{formatNumber(ref.cashBack)} $</Price>
                     </LineCounts>
                  </PriceLine>
               </NumberLine>
            ))}
         </LinesWrapper>
      </>
   )
}

export default ModalReferalInfo

const Title = styled.div`
   font-size: 28px;
   font-weight: 700;
   line-height: 30.8px;
   letter-spacing: -0.02em;
   padding: 30px 0 0 25px;
`

const NumberLine = styled.div``
const Name = styled.div``
const Count = styled.div`
   display: flex;
   align-items: center;
   > img {
      margin-right: 6px;
   }
`
const TitleLine = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 22px 0 14px 0;
`
const PriceLine = styled.div`
   display: flex;
   align-items: space-between;
   justify-content: center;
   gap: 10px;
   border-bottom: 1px solid #bcbcbc;
`

const Text = styled.div`
   color: #5f7385;
   font-size: 12px;
   font-weight: 400;
   line-height: 14.4px;
   letter-spacing: -0.02em;
`
const Price = styled.div`
   font-size: 14px;
   font-weight: 600;
   line-height: 15.4px;
   letter-spacing: -0.02em;
   margin: 12px 0 20px 0;
`
const LinesWrapper = styled.div`
   margin: 0 25px 25px 25px;
`
const LineCounts = styled.div`
   text-align: center;
   min-width: 32%;
`
