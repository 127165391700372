import classes from './Community.module.css'
import Lightning from '../../image/lightning.svg'
import Ellipse from '../../image/ellipse.svg'
import LinkButton from '../linkButton/LinkButton'
import styled from 'styled-components'

const Community = ({ theme }) => {
   return (
      <Wrapper theme={theme} className={`${classes.wrapper} wrapper`}>
         <Border className={classes.border} />
         <WrapperImg>
            <WrapperEllipse>
               <EllipseStyle src={Ellipse} alt='' />
            </WrapperEllipse>
            <LightningStyle src={Lightning} alt='' />
         </WrapperImg>
         <h2>
            Мы - сообщество
            <br /> ДЕЛО!
         </h2>
         <p>
            Поэтому здесь не будет большого текста о наших преимуществах и важности развития
            бизнесмена как личности. И, если вы разделяете наши взгляды -
            <br />
            <span>давайте перейдем к ДЕЛУ?</span>
         </p>
         <p>
            Присоединяйтесь в <a href='https://t.me/perehodikdelo'>официальный канал </a>портала,
            все главные новости и обсуждения там!
         </p>
         <LinkWrapper>
            <LinkButton color={'gray'} href={'https://t.me/perehodikdelo'} />
         </LinkWrapper>
      </Wrapper>
   )
}

export default Community

const WrapperEllipse = styled.div`
   width: 100%;
   height: 100%;
`

const EllipseStyle = styled.img`
   width: 100%;
`

const LightningStyle = styled.img`
   z-index: 1;
   position: absolute;
   top: 10px;
`

const WrapperImg = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   height: 200px;
`

const Border = styled.div`
   width: 100%;
   height: 20px;
   border-bottom-right-radius: 30px;
`

const LinkWrapper = styled.div`
   position: absolute;
   bottom: -21px;
`

const Wrapper = styled.div`
   margin-top: -12px;
   display: flex;
   align-items: center;
   flex-direction: column;
   text-align: center;
   justify-content: center;
   letter-spacing: -0.02em;
   font-size: 16px;
   line-height: 20.8px;
   position: relative;
   padding-bottom: 30px;
   border-bottom-left-radius: 30px;
   border-bottom-right-radius: 30px;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};

   h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 35.2px;
      margin: 20px 0 10px 0;
   }
   p {
      font-weight: 400;
      width: 90%;
      margin: 6px 0 45px 0;
      span {
         font-weight: 700;
      }
      a {
         color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
         text-decoration: underline;
      }
   }
`
