import { checkCurrencyDataValidity } from '../thunks/currencyThunks';
import {checkUserDataValidity} from '../thunks/appThunks';

const checkDataValidityMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === 'INIT' || action.type === 'SOME_ACTION_TYPE_THAT_TRIGGERS_VALIDITY_CHECK') {
    //Вызов thunks для всех данных с проверкой их актуальности и загрузки при необходимости
    store.dispatch(checkCurrencyDataValidity(useCloudStorageResult, useTelegramResult));
    store.dispatch(checkUserDataValidity(useCloudStorageResult, useTelegramResult));
  }
  return result;
};

export default checkDataValidityMiddleware;