import styled from 'styled-components'
import InfoIcon from '../../image/infoIconBorder.svg'
import HeartIcon from '../../image/heartIcon.svg'
import React, { useState } from 'react'
import classes from './Header.module.css'
import { useTelegram } from '../../hooks/useTelegram'
import { text } from '../../translate'
import Cross from '../../image/crossIcon.svg'

const Header = ({ title, isFav, setIsFav }) => {
   const { lang } = useTelegram()
   const [showAdditionalButtons, setShowAdditionalButtons] = useState(false)

   const pressShowInfo = () => {
      setShowAdditionalButtons(!showAdditionalButtons)
   }
   return (
      <div className={classes.wraper}>
         <InfoWrapper className='wrapper'>
            {!showAdditionalButtons ? (
               <>
                  <Info onClick={pressShowInfo}>
                     <p>{text[lang].header.info}</p>
                     <img src={InfoIcon} alt='info' />
                  </Info>
                  <Favorites onClick={() => setIsFav(!isFav)} isFav={isFav}>
                     <p>{text[lang].header.favorite}</p>
                     <img src={HeartIcon} alt='heart' />
                  </Favorites>
               </>
            ) : (
               <InfoBlock>
                  <div>{title ?? text[lang].header.infoText}</div>
                  <img src={Cross} alt='cross' onClick={pressShowInfo} />
               </InfoBlock>
            )}
         </InfoWrapper>
      </div>
   )
}

export default Header

const InfoBlock = styled.div`
   background: linear-gradient(90deg, rgba(182, 252, 118, 1) 30%, rgba(206, 252, 153, 1) 100%);
   color: #000;
   display: flex;
   justify-content: start;
   align-items: center;
   text-align: start;
   position: relative;
   border-radius: 16px;
   padding: 10px 0;
   font-size: 12px;

   div {
      max-width: 85%;
      margin-left: 14px;
   }

   img {
      position: absolute;
      top: 8px;
      right: 12px;
   }
`

const Info = styled.div`
   background: linear-gradient(90deg, rgba(182, 252, 118, 1) 30%, rgba(206, 252, 153, 1) 100%);
   color: #000;
   border-radius: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 52px;
   min-width: 48%;
   > img {
      margin-left: 6px;
   }
   transition: all 0.3s ease;

   &:hover {
      box-shadow: 1px 2px 20px gray;
      cursor: pointer;
   }
`

const Favorites = styled.div`
   background: linear-gradient(90deg, rgba(81, 96, 111, 1) 30%, rgba(99, 114, 129, 1) 100%);
   color: #fff;
   border-radius: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 52px;
   min-width: 48%;
   opacity: ${(props) => (props.isFav ? '1' : '0.6')};

   > img {
      margin-left: 6px;
   }
   transition: all 0.3s ease;

   &:hover {
      box-shadow: 1px 2px 20px gray;
      cursor: pointer;
   }
`

const InfoWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 12px;
   align-items: center;
   color: #fff;
   font-size: 14px;
   font-weight: 700;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   height: 88px;
`
