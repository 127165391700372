import classes from './Modal.module.css'
import CrossImg from '../../image/crossIcon.svg'

const Modal = ({ children, onCloseModal }) => {
   return (
      <div className={classes.wrapper}>
         <div className={classes.content + ' wrapper'}>
            <div>{children}</div>
            <img
               className={classes.crossImg}
               src={CrossImg}
               alt='CrossImg'
               onClick={onCloseModal}
            />
         </div>
         <div className={classes.closeBG} onClick={onCloseModal} />
      </div>
   )
}

export default Modal
