import React from 'react'
import classes from './Range.module.css'
import { useTelegram } from '../../../hooks/useTelegram'
import { text } from '../../../translate'

const Range = ({ title, time, inputFrom, setInputFrom, inputTo, setInputTo }) => {
   const { lang } = useTelegram()

   // const formatNumber = (number) => {
   //    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   // }

   // const handleInputChange = (setter) => (e) => {
   //    let value = e.target.value.replace(/\D/g, '')

   //    if (value.length > 6) {
   //       value = value.slice(0, 6)
   //    }

   //    if (!isNaN(value) && (value === '' || parseInt(value) > 0)) {
   //       setter(formatNumber(value))
   //    }
   // }

   // const handleInputBlur = () => {
   //    dispatch(setPriceRange({ from: inputFrom, to: inputTo }))
   // }

   const handleKeyPress = (e) => {
      if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
         e.preventDefault()
      }
   }

   return (
      <div className={classes._}>
         <div className={classes.nameInput}>{title ?? text[lang].filter.price}</div>
         <div className={classes.inputWrapper}>
            <div className={classes.inputFrom}>
               <div>{text[lang].filter.from}</div>
               <input
                  className={classes.input}
                  type='text'
                  placeholder={`${time ? '0' : '0'}`}
                  value={inputFrom}
                  onChange={(e) => setInputFrom(e.target.value)}
                  onKeyDown={handleKeyPress}
               />
            </div>

            <div className={classes.inputTo}>
               <div>{text[lang].filter.to}</div>
               <input
                  className={classes.input}
                  type='text'
                  placeholder={`${time ? '10 лет' : '500 000'}`}
                  value={inputTo}
                  onChange={(e) => setInputTo(e.target.value)}
                  // onBlur={handleInputBlur}
                  onKeyDown={handleKeyPress}
               />
            </div>
         </div>
      </div>
   )
}

export default Range
