import { combineReducers } from "redux";
import appReducer  from "./appReducer";
import currencyReducer from './currencyReducer';
import timeReducer from './timeReducer';
import headerReducer from './headerReducer';
import filterReducer from "./filterReducer";
import catalogReducer from "./catalogReducer";
import propertyReducer from "./propertyReducer";
import estateReducer from "./estateReducer";
const hash = window.location.hash.slice(1);
const tgWebAppPlatformRegex = /tgWebAppPlatform=([^&]*)/;
const matchTgWebAppPlatform = hash.match(tgWebAppPlatformRegex);

if (matchTgWebAppPlatform) {
  const tgWebAppPlatform = matchTgWebAppPlatform[1];
}

const rootReducer = combineReducers({
  app: appReducer,
  currency: currencyReducer,
  time: timeReducer,
  header: headerReducer,
  filter: filterReducer,
  catalog: catalogReducer,
  property: propertyReducer,
  estate: estateReducer,
  });
  
  export default rootReducer;