import AboutItem from './AboutItem/AboutItem'
import Ball from '../../image/ball.png'
import Case from '../../image/case.png'
import Graph from '../../image/graphick.png'
import House from '../../image/House.png'
import Expert from '../../image/expert.png'
// import AirCar from '../../image/airCar.png'
// import Start from '../../image/start.png'
import styled from 'styled-components'

const aboutContent = [
   { id: 1, name: 'Академия', link: '/academy', image: Ball },
   { id: 2, name: 'Мероприятия', link: '/biz-event', image: Case },
   { id: 3, name: 'Инвестиционный клуб', link: '/investment', image: Graph },
   { id: 4, name: 'Недвижимость', link: '/property', image: House },
   { id: 5, name: 'Услуги от экспертов', link: '/expert-service', image:  Expert },
   // { id: 5, name: 'Путешествия', link: '/grid-bot', image: AirCar },
   // { id: 6, name: 'Здоровый выбор и экология', link: '', image: Start },
]
// href="https://t.me/delo_app_bot"
const About = ({ theme }) => {
   return (
      <Wrapper className={'wrapper'}>
         <Title id={'test'} theme={theme}>
            Наше сообщество это:
         </Title>
         <ItemWrapper>
            {aboutContent.map((item) => (
               <AboutItem key={item.id} {...item} />
            ))}
         </ItemWrapper>
      </Wrapper>
   )
}

export default About

const Wrapper = styled.div`
   margin-top: 70px;
`

const ItemWrapper = styled.div`
   padding-top: 10px;
`

const Title = styled.div`
   font-size: 30px;
   font-weight: 700;
   line-height: 39px;
   letter-spacing: -0.02em;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
`
