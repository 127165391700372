export const INIT_PROPERTY_PAGE = 'INIT_PROPERTY_PAGE';
export const SAVE_END_INDEX = 'SAVE_END_INDEX';
export const SAVE_SCROLL_TOP = 'SAVE_SCROLL_TOP';
export const SET_SELECTED_GOOD = 'SET_SELECTED_GOOD';

export const saveEndIndex = (endIndex) => ({
    type: SAVE_END_INDEX,
    payload: endIndex
  }); 
  export const saveScrollTop = (scrollTop) => ({
    type: SAVE_SCROLL_TOP,
    payload: scrollTop
  });
  export const setSelectedGood = (id) => ({
    type: SET_SELECTED_GOOD,
    payload: id,
  });
  export const initPage = () => ({
    type: INIT_PROPERTY_PAGE,
  });