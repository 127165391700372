export const MARK_FAVORITES = 'MARK_FAVORITES';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const TOGGLE_FAVORITE  = 'TOGGLE_FAVORITE';
export const INIT_CATALOG = 'INIT_CATALOG';
export const FETCH_CATALOG_DATA = 'FETCH_CATALOG_DATA'; 
export const FINISH_FETCHING_CATALOG_DATA = 'FINISH_FETCHING_CATALOG_DATA';

export const initCatalog = () => ({
  type: INIT_CATALOG
});

export const fetchCatalogData = () => ({
  type: FETCH_CATALOG_DATA
});

export const finishFetchingCatalogData = (catalogCards) => ({
  type: FINISH_FETCHING_CATALOG_DATA,
  payload: catalogCards,
});
export const markFavorites = (favorites) => ({
  type: MARK_FAVORITES,
  payload: favorites,
});

export const toggleFavorite = (id) => ({
    type: TOGGLE_FAVORITE,
    payload: id,
});

export const applyFilters = (filteredCards) => ({
    type: APPLY_FILTERS,
    payload: filteredCards
  });