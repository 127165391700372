import React from 'react'
import { useDispatch } from 'react-redux'
import Interesting from '../../components/interesting/Interesting'
import PersonalInfo from '../../components/personalInfo/PersonalInfo'
import Profile from '../../components/profile/Profile'
import ReferalStats from '../../components/referelStats/ReferalStats'
import TooltipsProfile from '../../components/tooltipsProfile/TooltipsProfile'
import classes from './ProfilePage.module.css'
import { useTelegram } from '../../hooks/useTelegram'
import { setPath } from '../../store/actions/appActions'
const ProfilePage = ({ userBack }) => {
   const { webApp, user } = useTelegram()
   const dispatch = useDispatch()
   dispatch(setPath('one'))

   const theme = webApp.colorScheme
   const userName = `${user.first_name} ${user.last_name}`
   const nick = user.username
   const refValue = window
      .btoa(user.id + '')
      .split('')
      .map((i) => (i === '=' ? '' : i))
      .join('')

   return (
      <div className={classes.wrapper}>
         <Profile
            refValue={refValue}
            userName={userName}
            theme={theme}
            nick={nick}
            photoUrl={userBack?.avatar_url}
            balance={userBack?.balance}
            subscribes={userBack?.subscription?.name}
         />
         <TooltipsProfile />
         <PersonalInfo theme={theme} user={userBack ?? {}} />
         <Interesting theme={theme} />
         <ReferalStats theme={theme} userBack={userBack} />
      </div>
   )
}

export default ProfilePage
