import {TOGGLE_FAVORITE_BUTTON, OPEN_LINK} from '../actions/headerActions';
const initialState = {
    favoriteButtonPressed: false,
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_FAVORITE_BUTTON:
            return {
                ...state,
                favoriteButtonPressed: !state.favoriteButtonPressed,
            };
        case OPEN_LINK:
        return {
            ...state,
        };
        default:
            return state;
    }
};

export default headerReducer;