import { useNavigate } from 'react-router-dom'
import { useTelegram } from '../../hooks/useTelegram'
import ArrowLink from '../../image/arrowLink.svg'
import ArrowWhite from '../../image/ArrowWhite.svg'
import styled from 'styled-components'

const LinkButton = ({ link, color, rotate, mainButton, href }) => {
   const { webApp } = useTelegram()
   const navigate = useNavigate()
   return (
      <>
         {href ? (
            <LinkWrapper href={href} color={color} target='_blank'>
               <Image
                  src={color === 'gray' ? ArrowWhite : color === 'black' ? ArrowWhite : ArrowLink}
                  alt='arrow'
                  rotate={rotate}
                  color={color}
               />
            </LinkWrapper>
         ) : (
            <Wrapper
               onClick={() => {
                  webApp.BackButton.show()
                  navigate(link)
                  mainButton && webApp.MainButton.show()
               }}
               color={color}
            >
               <Image
                  src={color === 'gray' ? ArrowWhite : color === 'black' ? ArrowWhite : ArrowLink}
                  alt='arrow'
                  rotate={rotate}
                  color={color}
               />
            </Wrapper>
         )}
      </>
   )
}

export default LinkButton

const LinkWrapper = styled.a`
   border-radius: 50%;
   width: 40px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   background: ${(props) =>
      props.color === 'gray'
         ? '#23323f'
         : props.color === 'white'
         ? '#fff'
         : props.color === 'black'
         ? '#000'
         : '#beff8b'};

   transition: all 0.3s ease;

   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`

const Image = styled.img`
   ${(props) =>
      props.rotate === 'left'
         ? 'transform: rotate(225deg)'
         : props.rotate === 'right'
         ? 'transform: rotate(45deg)'
         : ''}
   ${(props) => (props.color === 'black' ? 'transform: rotate(225deg)' : '')}
`

const Wrapper = styled.div`
   border-radius: 50%;
   width: 40px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   background: ${(props) =>
      props.color === 'gray'
         ? '#23323f'
         : props.color === 'white'
         ? '#fff'
         : props.color === 'black'
         ? '#000'
         : '#beff8b'};

   transition: all 0.3s ease;

   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`
