import React, { useState } from 'react'
import classes from './Select.module.css'
import AcceptIcon from '../../../image/Accept.svg'

const Select = ({ name, smallSelect, options, state, setState }) => {
   const [isOpen, setIsOpen] = useState(false)

   const toggleDropdown = () => {
      setIsOpen(!isOpen)
   }

   const activeFilterName = options.filter((item) => item.value === state || item.name === state)[0]
      ?.name

   return (
      <div className={classes._}>
         <div
            className={classes.select + ' ' + (isOpen && classes.selectOpen)}
            onClick={toggleDropdown}
         >
            <div className={classes.title}>
               <div>{activeFilterName ?? name}</div>
               <svg
                  className={isOpen ? classes.strRotate : classes.str}
                  width='14'
                  height='8'
                  viewBox='0 0 14 8'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     fillRule='evenodd'
                     clipRule='evenodd'
                     d='M0.158175 0.158174C0.208187 0.108035 0.2676 0.0682552 0.33301 0.041113C0.398421 0.0139708 0.468543 0 0.539361 0C0.610179 0 0.680301 0.0139708 0.745711 0.041113C0.811122 0.0682552 0.870534 0.108035 0.920547 0.158174L7.00014 6.23885L13.0797 0.158174C13.1298 0.108116 13.1892 0.0684081 13.2546 0.0413168C13.32 0.0142256 13.3901 0.000281888 13.4609 0.000281888C13.5317 0.000281888 13.6018 0.0142256 13.6672 0.0413168C13.7326 0.0684081 13.7921 0.108116 13.8421 0.158174C13.8922 0.208233 13.9319 0.26766 13.959 0.333064C13.9861 0.398468 14 0.468568 14 0.539361C14 0.610153 13.9861 0.680253 13.959 0.745657C13.9319 0.811061 13.8922 0.870489 13.8421 0.920547L7.38133 7.38133C7.33132 7.43147 7.2719 7.47125 7.20649 7.49839C7.14108 7.52553 7.07096 7.5395 7.00014 7.5395C6.92932 7.5395 6.8592 7.52553 6.79379 7.49839C6.72838 7.47125 6.66897 7.43147 6.61896 7.38133L0.158175 0.920547C0.108036 0.870534 0.0682557 0.811121 0.0411135 0.745711C0.0139713 0.680301 0 0.610179 0 0.539361C0 0.468543 0.0139713 0.39842 0.0411135 0.33301C0.0682557 0.2676 0.108036 0.208187 0.158175 0.158174Z'
                     fill={'#000'}
                  />
               </svg>
            </div>
         </div>
         {isOpen && (
            <div
               className={classes.selectBlock}
               style={{ display: `${smallSelect ? 'flex' : 'block'}` }}
            >
               {options.map((option) => (
                  <div
                     key={option.id}
                     className={classes.options}
                     onClick={() =>
                        setState(
                           state.includes(option.value ? option.value : option.name)
                              ? ''
                              : option.value
                              ? option.value
                              : option.name,
                        )
                     }
                  >
                     <div
                        className={`${classes.radio} ${
                           state?.includes(option.value ? option.value : option.name) &&
                           classes.radioActive
                        }`}
                     >
                        <img
                           src={AcceptIcon}
                           alt='accept'
                           className={`${classes.acceptImg} ${
                              state?.includes(option.value ? option.value : option.name) &&
                              classes.acceptImgActive
                           }`}
                        />
                     </div>
                     {option?.name}
                  </div>
               ))}
            </div>
         )}
         {isOpen && <div className={classes.fon} onClick={() => setIsOpen(false)}></div>}
      </div>
   )
}

export default Select
