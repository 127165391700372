import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initPage } from '../../store/actions/propertyActions'
import { initCatalog } from '../../store/actions/catalogActions'
import { fetchFilters } from '../../store/actions/filterActions'
import { useTelegram } from '../../hooks/useTelegram'
import Filter from '../../components/filter/Filter'
import Header from '../../components/header/Header'
import Catalog from '../../components/catalog/Catalog'
import classes from './PropertyPage.module.css'
import Estate from '../../components/estate/Estate'
import $api from '../../http'
import { setPath } from '../../store/actions/appActions'
const PropertyPage = () => {
   const { webApp } = useTelegram()
   const dispatch = useDispatch()
   dispatch(setPath('one'))
   const theme = webApp.colorScheme
   const selectedGood = useSelector((state) => state?.property?.selectedGood)
   useEffect(() => {
      dispatch(initPage())
      dispatch(fetchFilters())
      dispatch(initCatalog())
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const [items, setItems] = useState([])

   const [isFav, setIsFav] = useState(false)
   const [clickFav, setcCickFav] = useState(1)

   useMemo(async () => {
      const response = await $api.get('/complex')
      setItems(response.data.items)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFav, clickFav])

   const [cardItems, setCardItems] = useState(items)

   return (
      <div className={classes.wrapper}>
         {selectedGood !== -1 && selectedGood ? (
            <Estate />
         ) : (
            <div>
               <Header theme={theme} isFav={isFav} setIsFav={setIsFav} />
               <Filter
                  items={items || []}
                  setCardItems={setCardItems}
                  theme={theme}
                  isFav={isFav}
               />
               <Catalog
                  items={cardItems || []}
                  theme={theme}
                  isFav={isFav}
                  setcCickFav={setcCickFav}
                  clickFav={clickFav}
               />
            </div>
         )}
      </div>
   )
}

export default PropertyPage
