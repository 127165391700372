import tg from "../../image/telegram.svg";
import insta from "../../image/instagram.svg";
import youtube from "../../image/youtube.svg";
import vk from "../../image/VK.svg";
import styled from "styled-components";

const SocialLink = () => {
  return (
    <Wrapper>
      <img src={tg} alt="tg" />
      <img src={insta} alt="insta" />
      <img src={youtube} alt="youtube" />
      <img src={vk} alt="vk" />
    </Wrapper>
  );
};

export default SocialLink;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 20px;

  img {
    margin: 10px;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
`;
