import { useMemo, useState } from 'react'
import { useTelegram } from '../../hooks/useTelegram'
import classes from './InvestionPage.module.css'
import Header from '../../components/header/Header'
import FilterInvestion from '../../components/filter/FilterInvestion'
import { text } from '../../translate'
import SotkaCard from '../../components/sotkaCard/SotkaCard'
import $api from '../../http'
import { useDispatch } from 'react-redux'
import { setPath } from '../../store/actions/appActions'

const InvestionPage = () => {
   const { webApp } = useTelegram()
   const theme = webApp.colorScheme
   const lang = 'ru'
   const [investData, setInvestData] = useState([])
   const [clickFav, setcCickFav] = useState(1)
   const [isFav, setIsFav] = useState(false)
   const dispatch = useDispatch()
   useMemo(async () => {
      const response = await $api.get('/investments')
      setInvestData(response.data)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFav, clickFav])
   dispatch(setPath('one'))
   const [filterData, setFilterData] = useState([])

   return (
      <div className={classes.wrapper}>
         <Header
            theme={theme}
            title={text[lang].header.investionText}
            isFav={isFav}
            setIsFav={setIsFav}
         />
         <FilterInvestion
            theme={theme}
            investData={investData}
            setFilterData={setFilterData}
            isFav={isFav}
         />
         {filterData.map((item) => (
            <SotkaCard {...item} clickFav={clickFav} setcCickFav={setcCickFav} />
         ))}
      </div>
   )
}

export default InvestionPage
