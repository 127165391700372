import Champion from '../../image/championSmallIcon.svg'
import Heart from '../../image/heartSmallIcon.svg'
import Calendar from '../../image/calendar.svg'
import styled from 'styled-components'
import { useTelegram } from '../../hooks/useTelegram';
const TooltipsProfile = () => {
   const { webApp } = useTelegram();
   const handleHeartClick = () => {
      webApp.openTelegramLink('https://t.me/delosupport');
   };
   return (
      <Wrapper className={'wrapper'}>
         <Achivs>
            <img src={Champion} alt='achivs' />
            <p>Достижения</p>
         </Achivs>
         <CalendarS>
            <Span>8</Span>
            <img src={Calendar} alt='calendar' />
            <p>Календарь</p>
         </CalendarS>
         <HeartS onClick={handleHeartClick}>
            <img src={Heart} alt='heart' />
            <p>Служба заботы</p>
         </HeartS>
      </Wrapper>
   )
}

export default TooltipsProfile

const Achivs = styled.div`
   background: linear-gradient(90deg, rgba(182, 252, 118, 1) 30%, rgba(206, 252, 153, 1) 100%);
   color: #000;
`
const CalendarS = styled.div`
   background: #23323f;
`
const HeartS = styled.div`
   background: linear-gradient(90deg, rgba(81, 96, 111, 1) 30%, rgba(99, 114, 129, 1) 100%);
`

const Span = styled.div`
   position: absolute;
   font-size: 72.42px;
   font-weight: 700;
   line-height: 94.15px;
   letter-spacing: -0.02em;
   color: #344a5e;
   transform: rotate(22.5deg);
   top: -10px;
`

const Wrapper = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 14px;
   font-weight: 700;
   line-height: 18.2px;
   letter-spacing: -0.02em;
   color: #fff;
   display: flex;
   justify-content: space-between;
   margin-top: 45px;
   > div {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      width: 98px;
      height: 54px;
      padding: 10px 10px 20px 10px;
      position: relative;

      p {
         margin: 0;
         display: flex;
         height: 100%;
         z-index: 1;
         align-items: end;
      }

      img {
         align-self: flex-end;
      }
   }
`
