import React, { useState } from 'react'
import styled from 'styled-components'
import $api from '../../http'

const AcademyPrice = ({ type, price, description, id, course_id, bonus, user, theme }) => {
   const [isOpen, setIsOpen] = useState(false)
   const path = window.location.pathname;
   const toggleOpen = () => {
      setIsOpen(!isOpen)
   }

   const formatingNumber = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   }

   const [natification, setNatification] = useState('')

   const paymentFn = async () => {
      if (user?.balance < price) {
         setNatification('У Вас не достаточно средств!')
         setTimeout(() => {
            setNatification('')
            window.location = '/profile'
         }, 3000)
         setIsOpen(false)
      } else {
         try {
            await $api.post(path.includes('/expert-service-card') ? '/payments/expert_service' : '/payments/education', {
               [path.includes('/expert-service-card') ? 'expert_service_id' : 'education_id']: course_id,
               payment_rate_id: id,
            })
            setNatification(`Вы успешно преобрели курс ${type}`)
            setIsOpen(false)
            setTimeout(() => {
               setNatification('')
            }, 3000)
         } catch {
            setNatification('Что-то пошло не так, попробуйте позже!')
            setTimeout(() => {
               setNatification('')
            }, 3000)
         }
      }
   }

   return (
      <Wrapper onClick={toggleOpen}>
         <Title>
            <Name>
               <span>Тариф</span>
               {type.toUpperCase()}
            </Name>
            <Price>{formatingNumber(price)} ₽</Price>
            <Arrow isOpen={isOpen}>
               <svg
                  width='14'
                  height='8'
                  viewBox='0 0 14 8'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     fillRule='evenodd'
                     clipRule='evenodd'
                     d='M0.158175 0.158174C0.208187 0.108035 0.2676 0.0682552 0.33301 0.041113C0.398421 0.0139708 0.468543 0 0.539361 0C0.610179 0 0.680301 0.0139708 0.745711 0.041113C0.811122 0.0682552 0.870534 0.108035 0.920547 0.158174L7.00014 6.23885L13.0797 0.158174C13.1298 0.108116 13.1892 0.0684081 13.2546 0.0413168C13.32 0.0142256 13.3901 0.000281888 13.4609 0.000281888C13.5317 0.000281888 13.6018 0.0142256 13.6672 0.0413168C13.7326 0.0684081 13.7921 0.108116 13.8421 0.158174C13.8922 0.208233 13.9319 0.26766 13.959 0.333064C13.9861 0.398468 14 0.468568 14 0.539361C14 0.610153 13.9861 0.680253 13.959 0.745657C13.9319 0.811061 13.8922 0.870489 13.8421 0.920547L7.38133 7.38133C7.33132 7.43147 7.2719 7.47125 7.20649 7.49839C7.14108 7.52553 7.07096 7.5395 7.00014 7.5395C6.92932 7.5395 6.8592 7.52553 6.79379 7.49839C6.72838 7.47125 6.66897 7.43147 6.61896 7.38133L0.158175 0.920547C0.108036 0.870534 0.0682557 0.811121 0.0411135 0.745711C0.0139713 0.680301 0 0.610179 0 0.539361C0 0.468543 0.0139713 0.39842 0.0411135 0.33301C0.0682557 0.2676 0.108036 0.208187 0.158175 0.158174Z'
                     fill={'#fff'}
                  />
               </svg>
            </Arrow>
         </Title>
         <Content isOpen={isOpen}>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            {!!bonus?.length && (
               <BonusWrapper>
                  <svg
                     width='19'
                     height='19'
                     viewBox='0 0 19 19'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                  >
                     <g clip-path='url(#clip0_875_848)'>
                        <path
                           d='M4.13713 4.33259C3.99831 4.07739 3.91926 3.78514 3.91926 3.47471C3.91926 2.48181 4.72705 1.67414 5.71983 1.67414C8.20643 1.67414 8.84275 3.44648 8.99225 4.33259H10.0204C10.1699 3.44648 10.8062 1.67414 13.2928 1.67414C14.2856 1.67414 15.0934 2.48181 15.0934 3.47471C15.0934 3.78514 15.0144 4.07739 14.8755 4.33259H15.8985C15.9876 4.06254 16.0361 3.77421 16.0361 3.47471C16.0361 1.96198 14.8054 0.731445 13.2928 0.731445C11.2235 0.731445 10.0808 1.77541 9.5127 2.88651C8.83612 1.60614 7.49057 0.731445 5.94445 0.731445H5.71983C4.20722 0.731445 2.97656 1.96198 2.97656 3.47471C2.97656 3.77421 3.02505 4.06254 3.11404 4.33259H4.13713Z'
                           fill='white'
                        />
                        <path d='M7.95898 5.27539H11.0551V9.75208H7.95898V5.27539Z' fill='white' />
                        <path
                           d='M7.01644 9.75208V5.27539H2.94395C2.11873 5.27539 1.46289 5.94485 1.46289 6.75645V8.27102C1.46289 9.0987 2.13505 9.75208 2.94395 9.75208H7.01644Z'
                           fill='white'
                        />
                        <path
                           d='M16.0705 5.27539H11.998V9.75208H16.0705C16.8965 9.75208 17.5516 9.08189 17.5516 8.27102V6.75645C17.5516 5.93061 16.8815 5.27539 16.0705 5.27539Z'
                           fill='white'
                        />
                        <path d='M8.46387 10.6953H10.5503V16.8207H8.46387V10.6953Z' fill='white' />
                        <path
                           d='M11.4922 16.8207H15.0598C15.8874 16.8207 16.5409 16.1486 16.5409 15.3397V10.6953H11.4922V16.8207Z'
                           fill='white'
                        />
                        <path
                           d='M2.4707 15.3397C2.4707 16.1656 3.14102 16.8207 3.95176 16.8207H7.51939V10.6953H2.4707V15.3397Z'
                           fill='white'
                        />
                     </g>
                     <defs>
                        <clipPath id='clip0_875_848'>
                           <rect
                              width='16.0886'
                              height='16.0886'
                              fill='white'
                              transform='translate(1.46289 0.731445)'
                           />
                        </clipPath>
                     </defs>
                  </svg>
                  <p>
                     <span>БОНУС:</span>
                     {bonus}
                  </p>
               </BonusWrapper>
            )}

            <Button
               onClick={(e) => {
                  e.stopPropagation()
                  paymentFn()
               }}
            >
               Купить
            </Button>
         </Content>
         {!!natification && <Natification theme={theme}>{natification}</Natification>}
      </Wrapper>
   )
}

export default AcademyPrice

const BonusWrapper = styled.div`
   display: flex;
   align-items: center;
   span {
      font-weight: 700;
      margin: 0 6px;
   }
`

const Natification = styled.div`
   padding: 10px 20px;
   font-size: 14px;
   border-radius: 8px;
   position: fixed;
   bottom: 30px;
   right: 30px;
   box-shadow: 1px 2px 20px gray;

   ${(props) =>
      props.theme === 'light' ? 'background: #000; color: #fff;' : 'background: #fff; color: #000;'}
`

const Arrow = styled.div`
   transition: all 0.3s ease;
   transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
   display: flex;
   align-items: center;
   margin-left: 12px;
`

const Price = styled.div`
   font-size: 16px;
   text-wrap: nowrap;
`

const Name = styled.div`
   font-size: 16px;
   font-weight: 700;
   width: 75%;
   span {
      font-weight: 400;
      margin-right: 5px;
      font-size: 15px;
   }
`

const Button = styled.div`
   font-size: 14px;
   padding: 10px 0;
   width: 100%;
   background: #beff8b;
   border-radius: 28px;
   cursor: pointer;
   transition: all 0.3s ease;
   text-align: center;
   color: #000;
   margin-top: 25px;
   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`

const Title = styled.div`
   font-size: 24px;
   max-height: 11vh;
   font-weight: 700;
   line-height: 31.2px;
   letter-spacing: -0.02em;
   display: flex;
   font-family: system-ui;
`

const Wrapper = styled.div`
   border-radius: 26px;
   padding: 12px 25px 10px 20px;
   margin-bottom: 4px;
   background: rgb(95, 115, 133);
   font-family: system-ui;
   color: #fff;
   &:hover {
      cursor: pointer;
   }
`
const Content = styled.div`
   max-height: ${(props) => (props.isOpen ? '500px' : '0')};
   overflow: hidden;
   transition: max-height 0.3s ease-in-out;
`
