import React from 'react'
// import { useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/swiper-bundle.css'
import 'swiper/css/navigation'
import classes from './ImageSlider.module.css'
// import BigHouse from '../../image/BigHouse.png'

const ImageSlider = ({ photos, estates }) => {
   const formatNumber = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   }
   return (
      <div className={classes.swiper_block}>
         <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            navigation={true}
            modules={[Navigation]}
            pagination={{ clickable: true }}
            className={classes.swiper_custom}
         >
            {!!estates?.length ? (
               <>
                  {estates?.map((path, index) => (
                     <SwiperSlide key={index} className={classes.swiper_slide}>
                        <div className={classes.wrapperSlide}>
                           <div className={classes.textTitle}>{formatNumber(path?.price)} {path?.currency === 'rub' ? '₽' : path?.currency === 'euro' ? '€' : ''}</div>
                           {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                           <img
                              src={path.images?.[0]}
                              alt={`Photo ${index}`}
                              className={classes.image}
                           />

                           <div className={classes.text}>{path?.descriptions[0]}</div>
                        </div>
                     </SwiperSlide>
                  ))}
               </>
            ) : (
               <>
                  {photos?.map((path, index) => (
                     <SwiperSlide key={index} className={classes.swiper_slide}>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src={path} alt={`Photo ${index}`} className={classes.image} />
                     </SwiperSlide>
                  ))}
               </>
            )}
         </Swiper>
      </div>
   )
}

export default ImageSlider
